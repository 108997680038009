.dz--wide {
    .dz-message {
        .dropzone__container {
            padding-top: calc(9 / 16 * 100%) !important;
        }
    }
}
.dz--no-padding {
    .dz-message {
        .dropzone__container {
            padding: 0 !important;
        }
    }
}
.dz--small {
    .dz-message {
        .dropzone__container {
            padding-top: 10% !important;
        }
    }
}
