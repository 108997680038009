/* BREAKPOINTS */
.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.questao__alternativa--easy {
  border-top: 3px solid #1bc5bd;
  border-radius: calc(0.42rem - 1px);
}
.questao__alternativa--moderate {
  border-top: 3px solid #ffa800;
  border-radius: calc(0.42rem - 1px);
}
.questao__alternativa--hard {
  border-top: 3px solid #f64e60;
  border-radius: calc(0.42rem - 1px);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: calc(0.42rem - 1px);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dz--wide .dz-message .dropzone__container {
  padding-top: calc(9 / 16 * 100%) !important;
}

.dz--no-padding .dz-message .dropzone__container {
  padding: 0 !important;
}

.dz--small .dz-message .dropzone__container {
  padding-top: 10% !important;
}

.col-selection {
  min-width: 5rem;
  width: 5rem;
}

.modal-dialog-fit {
  margin: 1.75rem auto;
  max-width: 80vw;
  /* If the key exists in the map */
  /* Prints a media query based on the value */
}
@media (min-width: 768px) {
  .modal-dialog-fit {
    max-width: fit-content;
  }
}

.modal-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 900;
}

.load__loading {
  background-color: #fff;
  position: relative;
}
.load__loading::before {
  animation: loading-line 1s infinite;
  content: "";
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  z-index: 100;
  background: linear-gradient(0.25turn, transparent, #fff, transparent), #ebedf2;
  background-repeat: no-repeat;
  background-position: calc(100% - 100rem) 0, 0 0;
}
@keyframes loading-line {
  to {
    background-position: calc(100% + 100rem) 0, 0 0;
  }
}
.load__loading * {
  display: none;
}

.chip {
  position: relative;
}
.chip__acao {
  display: none !important;
  right: 10px;
  position: absolute;
}
.chip__acao gpe-trash {
  position: relative;
  display: block;
  top: -2px;
}
.chip__label {
  transition: padding-right 0.2s linear 0s;
  max-width: 35vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chip:hover .chip__label {
  padding-right: 30px;
}
.chip:hover .chip__acao {
  display: block !important;
}