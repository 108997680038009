/* BREAKPOINTS */
@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);

	@return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@function breakpoint-infix($name, $breakpoints: $breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

@mixin for-phone-only {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin respond-to($breakpoint) {
	/* If the key exists in the map */
	@if map-has-key($breakpoints, $breakpoint) {
		/* Prints a media query based on the value */
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
	}
}
