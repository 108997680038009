$color-white: #fff;
$color-gray: #999;
$error-lightened: #FFE2E5;
$error: #F64E60;

$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
) !default;

$box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 
             0 1px 1px 0 rgb(0 0 0 / 14%),
             0 1px 3px 0 rgb(0 0 0 / 12%);


$border-radius: calc(0.42rem - 1px);

@for $i from 1 through 12 {
    .gap-#{$i} {
      gap: #{$i * 0.25}rem;
    }
  }
  