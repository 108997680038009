$easy: #1bc5bd;
$moderate: #ffa800;
$hard: #f64e60;

.questao {
    &__alternativa {
        &--easy {
            border-top: 3px solid $easy;
            border-radius: $border-radius;
        }

        &--moderate {
            border-top: 3px solid $moderate;
            border-radius: $border-radius;
        }

        &--hard {
            border-top: 3px solid $hard;
            border-radius: $border-radius;
        }
    }
}
