.componentTag {
	width: 100%;
	height: auto;
}

a.brand-logo:link,
a.brand-logo:hover
a.brand-logo:active,
a.brand-logo:visited {
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    margin: 18px 20px 18px 0;
}

.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed {
    color: #3699ff;
}

#kt_content {
    min-height: calc(100vh - 110px);
}
#kt_content.bg-img {
    background-image: url(assets/images/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.boxDashboard {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 170px);
    padding: 30px 0 0 0;
    border-radius: 30px 30px 0 0;
    background-color: #FFF;
    -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    margin: 3% 0 -30px 0;
}
.boxDashboard .boxBto {
    background-color: #FFF;
    border-radius: 20px;
    padding-bottom: 10px;
    transition: background-color 200ms ease-in-out;
}
.boxDashboard .boxBto.entidades:hover {
    background-color: rgb(130 119 201 / 20%);
}
.boxDashboard .boxBto.sincronizacao:hover {
    background-color: rgb(0 150 104 / 20%);
}
.boxDashboard .boxBto.usuarios:hover {
    background-color: rgb(86 132 201 / 20%);
}
.boxDashboard .boxBto.vouchers:hover {
    background-color: rgb(0 150 104 / 20%);
}
.boxDashboard .boxBto.bilingue:hover {
    background-color: rgb(0 150 104 / 20%);
}
.boxDashboard img {
    width: 130px;
    margin: 30px 0 10px 0;
}
.boxDashboard .boxBto .nome {
    color: #203d69;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.boxDashboard .boxBto .categoria {
    font-size: 13px;
    font-weight: 500;
}
.boxDashboard .boxBto.entidades .categoria {
    color: #645e99;
}
.boxDashboard .boxBto.usuarios .categoria {
    color: #5684c9;
}
.boxDashboard .boxBto.sincronizacao .categoria {
    color: #077b56;
}
.boxDashboard .boxBto.vouchers .categoria {
    color: #077b56;
}
.boxDashboard .boxBto.bilingue .categoria {
    color: #077b56;
}

.header .header-menu .menu-nav > .menu-item > .menu-link .menu-text {
    color: #ffffff;
}
.header .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link, .header .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #383e50;
}

.dropdown-menu.dropdown-menu-lg {
    width: 400px;
}

.offcanvas-overlay {
    background: rgba(0, 0, 0, 0.6);
}
.aside-menu .menu-nav > .menu-section {
    background-color: #10101d;
}
.aside-menu .menu-nav > .menu-section .menu-text {
    color: #afafb5;
}
.aside-menu .menu-nav > .menu-section.first {
    margin: 0;
}
/* .aside-menu .menu-nav > .menu-item > .menu-link {
    padding: 9px 25px 9px 55px;
} */
.aside-menu .menu-nav .menu-section .menu-icon {
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 30px;
    font-size: 1.3rem;
    line-height: 35px;
}
.table th, .table td {
    padding: 1rem 0.75rem;
}
.input-group.input-group-solid .form-control {
    background-color: #eaedf2;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f6f9;
}
.table th, .table td {
    border-top: 1px solid #d7dade;
}
.btn.btn-light {
    color: #7E8299;
    background-color: #ffffff;
    border-color: #d7dade;
}
.switch {
  display: inline-block;
}

.dropzone-default {
    padding: 0px !important;
}
.dropzone.dropzone-default.dropzone-primary {
    max-width: 120px;
    border-color: #c1c1c1;
}
.dz-message {
    margin: 0px !important;
}

.form__img {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #2b2b31;
}
.form__img img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

.boxFotoUsuario {
    width: 100%;
    height: auto;
    background-color: #ebecf5;
    padding-top: calc((4 / 3) * 100%);
    position: relative;
}
.boxFotoUsuario:hover {
    background-color: #dedfe8;
    cursor: pointer;
}
.boxFotoUsuario span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
}

.boxBannerDesktop,
.boxBannerMobile {
    width: 100%;
    height: auto;
    background-color: #ebecf5;
    position: relative;
    margin-bottom: 25px;
}
.boxBannerDesktop {
    padding-top: calc((400 / 1600) * 100%);
}
.boxBannerMobile {
    padding-top: calc((500 / 950) * 100%);
}
.boxBannerDesktop:hover,
.boxBannerMobile:hover {
    background-color: #dedfe8;
    cursor: pointer;
}
.boxFotoFachada {
    width: 100%;
    height: auto;
    background-color: #ebecf5;
    padding-top: calc((700 / 1280) * 100%);
    position: relative;
}
.boxFotoFachada:hover {
    background-color: #dedfe8;
    cursor: pointer;
}
.boxFotoFachada span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
}

.itensBooks {
    border: 1px solid #dbdfe4;
    padding: 10px;
    border-radius: 6px;
}
.itensBooks.disable {
    opacity: .5;
    filter: saturate(50%);
}

.nav.nav-pills .nav-link {
    background-color: #FFF;
    border: 1px #b5b5c3 solid !important;
}
.nav.nav-pills.nav-danger .show > .nav-link,
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) {
    background-color: #FFF;
}

.faExterno {
    font-size: 8rem;
    margin-bottom: 20px;
}

.boxLoading {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .8);
	z-index: 9999;
}
.boxLoading p {
	width: 80vw;
	color: #FFF;
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
	margin-top: 45px;
	font-size: 14px;
	font-weight: 400;
}
.sk-circle {
	margin: 0;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.sk-circle .sk-child:before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: #FFF;
	border-radius: 100%;
	-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-circleBounceDelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.btn.btn-light-primary.menu-left-disabled,
.btn.btn-hover-primary.menu-left-disabled:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary.menu-left-disabled:focus:not(.btn-text),
.btn.btn-hover-primary.menu-left-disabled.focus:not(.btn-text) {
    cursor: no-drop !important;
    background-color: #f3f6f9 !important;
    border-color: #f3f6f9 !important;
    color: #9c9c9c !important;
}

table.table tr.principal {
    border-top: 2px #afb1b5 solid;
    border-right: 2px #afb1b5 solid;
    border-left: 3px #afb1b5 solid;
    background-color: #d4d8de;
}
table.table tr.vinculado {
    border-right: 2px #afb1b5 solid;
    border-left: 3px #afb1b5 solid;
}
table.table tr.vinculado.ultimo {
    border-bottom: 2px #afb1b5 solid;
}
table.table tr.vinculado td {
    padding: 0.5rem 0.75rem;
}

.divisaoTurmas {
    margin: 30px 0 5px 0;
    width: 100%;
    padding: 0 0 5px;
    border-bottom: 1px solid #92a1ad;
}
.divisaoTurmas p {
    margin: 0;
    font-weight: 500;
    color: #0c324a;
    text-transform: uppercase;
}

.table .thead-division th {
    color: #1f1f2d;
    background-color: #c0d3e6;
    border-color: #c0d3e6;
}

.form-group label {
    font-weight: 600;
}

.switch.switch-success:not(.switch-outline) input:empty ~ span:before {
    background-color: #c1c1c1;
}
.switch.switch-success:not(.switch-outline) input:checked ~ span:before {
    background-color: #1BC5BD;
}

.ng-dropdown-panel {
    min-width: 100% !important;
    width: auto !important;
    right: auto !important;
    left: 0 !important;
}
.ngSelectRight .ng-dropdown-panel {
    min-width: 100% !important;
    width: auto !important;
    right: 0 !important;
    left: auto !important;
}

.ng-select.is-invalid .ng-select-container {
    border: 1px solid #F64E60;
}

.notAllowed {
    cursor: not-allowed;
}

#kt_footer {
    -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
}

.m-loader {
    position: relative; }
    .m-loader:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      border-top: 2px solid #07d;
      border-right: 2px solid transparent;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      -webkit-animation: m-loader-rotate 0.6s linear infinite;
      -moz-animation: m-loader-rotate 0.6s linear infinite;
      -ms-animation: m-loader-rotate 0.6s linear infinite;
      -o-animation: m-loader-rotate 0.6s linear infinite;
      animation: m-loader-rotate 0.6s linear infinite; }
    .m-loader:before {
      width: 1.6rem;
      height: 1.6rem;
      margin-top: -0.7rem;
      margin-left: -0.7rem;
      border-top-width: 3px;
      border-right-width: 3px; }
    .m-loader.m-loader--lg:before {
      width: 2rem;
      height: 2rem;
      margin-top: -1rem;
      margin-left: -1rem;
      border-top-width: 3px;
      border-right-width: 3px; }
    .m-loader.m-loader--sm:before {
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      border-top-width: 1px;
      border-right-width: 1px; }
    .m-loader.m-loader--right:before {
      left: auto;
      right: 0.7rem; }
    .m-loader.m-loader--right.m-loader--lg:before {
      right: 1rem; }
    .m-loader.m-loader--right.m-loader--sm:before {
      right: 0.5rem; }
    .m-loader.m-loader--right.btn {
      padding-right: 3.1rem; }
      .m-loader.m-loader--right.btn.m-loader--lg {
        padding-right: 4.5rem; }
      .m-loader.m-loader--right.btn.m-loader--sm {
        padding-right: 2.25rem; }
    .m-loader.m-loader--left:before {
      left: 1.4rem; }
    .m-loader.m-loader--left.m-loader--lg:before {
      left: 2rem; }
    .m-loader.m-loader--left.m-loader--sm:before {
      left: 1rem; }
    .m-loader.m-loader--left.btn {
      padding-left: 3.1rem; }
      .m-loader.m-loader--left.btn.m-loader--lg {
        padding-left: 4.5rem; }
      .m-loader.m-loader--left.btn.m-loader--sm {
        padding-left: 2.25rem; }

.m-loader:before {
border-top-color: #dfe2ea; }

.m-loader.m-loader--skin-dark:before {
border-top-color: #acafba; }

.m-loader.m-loader--brand:before {
border-top-color: #716aca; }

.m-loader.m-loader--metal:before {
border-top-color: #c4c5d6; }

.m-loader.m-loader--light:before {
border-top-color: #ffffff; }

.m-loader.m-loader--accent:before {
border-top-color: #00c5dc; }

.m-loader.m-loader--focus:before {
border-top-color: #9816f4; }

.m-loader.m-loader--primary:before {
border-top-color: #5867dd; }

.m-loader.m-loader--success:before {
border-top-color: #198c75; }

.m-loader.m-loader--info:before {
border-top-color: #36a3f7; }

.m-loader.m-loader--warning:before {
border-top-color: #ffb822; }

.m-loader.m-loader--danger:before {
border-top-color: #f4516c; }

@-webkit-keyframes m-loader-rotate {
to {
    transform: rotate(360deg); } }

@-moz-keyframes m-loader-rotate {
to {
    transform: rotate(360deg); } }

@-o-keyframes m-loader-rotate {
to {
    transform: rotate(360deg); } }

@keyframes m-loader-rotate {
to {
    transform: rotate(360deg); } }

.borderRed{
    border-color: #FF0000;
}
/* .switch input:empty ~ span:after {
    background-color: #F64E60;
}

.switch.switch-sm input:empty ~ span:after {
    height: 18px;
    width: 18px;
    top: 3px;
    margin-left: 3px;
}

.switch input:empty ~ span:before {
    border: 2px solid #F64E60;
    background-color: transparent;
} */
/* .form-control {
  border: 1px solid #191d31;
} */

.excelPrint{
    display: none;
}

.bgInativo{
    background-color: #FFEDEC !important;
}

@media(max-width: 991.98px) {
    .header-menu-wrapper {
        background: #1a1a27;
    }
    .header-menu-mobile {
        background-color: #1a1a27;
    }
    a.brand-logo:link,
    a.brand-logo:hover a.brand-logo:active,
    a.brand-logo:visited {
        text-align: center;
        display: block;
    }
    .header-menu-mobile .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading,
    .header-menu-mobile .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
        background-color: #282f48;
    }
    .header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-heading,
    .header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-link {
        background-color: #282f48;
    }
    .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading,
    .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
        background-color: #282f48;
    }
    .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
    .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
        color: #fff;
    }
}
@media(max-width: 767.98px) {
    .boxDashboard img {
        width: 100px;
    }
    .boxDashboard .boxBto .nome {
        font-size: 14px;
        line-height: 16px;
    }
}
