.modal-dialog-fit {
    margin: 1.75rem auto;
    max-width: 80vw;

    @include respond-to("md") {
        max-width: fit-content;
    }
}

.modal-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 900;
}
