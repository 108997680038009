.load__loading {
    background-color: #fff;
    position: relative;

    &::before {
        $animate-start: 100rem;
        $padding: 1rem;

        animation: loading-line 1s infinite;
        content: "";
        position: absolute;
        top: $padding;
        left: $padding;
        width: calc(100% - #{$padding * 2});
        height: calc(100% - #{$padding * 2});
        z-index: 100;

        background: linear-gradient(0.25turn, transparent, #fff, transparent),
            #ebedf2;
        background-repeat: no-repeat;
        background-position: calc(100% - #{$animate-start}) 0, 0 0;

        @keyframes loading-line {
            to {
                background-position: calc(100% + #{$animate-start}) 0, 0 0;
            }
        }
    }

    * {
        display: none;
    }
}
