.chip {
    position: relative;
    
    &__acao {
        display: none !important;
        right: 10px;
        position: absolute;

        gpe-trash{
            position: relative;
            display: block;
            top: -2px;
        }
    }
    
    &__label {
        transition: padding-right 0.2s linear 0s;
        max-width: 35vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    &:hover {
        .chip__label {
            padding-right: 30px;
        }
        
        .chip__acao {
            display: block !important;
        }
    }
}